import React from "react";
import { FooterContainer, NavLogo, IconLink, IconsContainer, Image} from "./Elements";
import {
  Facebook,
  Gmail,
  Linkedin,
  Youtube,
  Instagram,
  Github,
  Tiktok,
} from "helpers/assets";
const Footer = () => {
  const SOCIAL_ICONS = [
    {
      icon: Tiktok,
      href: "https://www.tiktok.com/@303_overwatch",
      name: "Tiktok",
    },
    {
      icon: Gmail,
      href: "https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=mentor@303overwatch.org",
      name: "Gmail",
    },
    { icon: Github, href: "https://github.com/303-OW", name: "Github" },
    {
      icon: Linkedin,
      href: "https://linkedin.com/in/william-ferguson-961810229",
      name: "Linkedin",
    },
    {
      icon: Youtube,
      href: "https://www.youtube.com/channel/UCmWJZ1AbHbpFeIbsDHIQKAQ",
      name: "Youtube",
    },
    {
      icon: Facebook,
      href: "https://m.facebook.com/303Overwatch?eav=AfZKTprT7SETTLZDb55lsk1IV-zPTfX7s_zvKr9l2_QG4gaFp7g3nCiLy6-BoeLCkGI&paipv=0&_rdr",
      name: "Facebook",
    },
    {
      icon: Instagram,
      href: "https://www.instagram.com/303_ow_team/",
      name: "Instagram",
    },
  ];
  return (
    <FooterContainer className="container">
      <NavLogo to="home" smooth={true}>
        <p>3M3</p>
      </NavLogo>
      <IconsContainer>
        {SOCIAL_ICONS?.map((icon, index) => {
          return (
            <IconLink
              key={`${icon.name}-${index}`}
              href={icon.href}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <Image src={icon.icon} />
            </IconLink>
          );
        })}
      </IconsContainer>
    </FooterContainer>
  );
};

export default Footer;
