import React, { useState } from "react";
import { HeroContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { hero2, hero3, hero4, hero5, hero6, hero1New } from "helpers/assets";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
import { StepperTransition } from "helpers/transitions";
const BACKGROUNDS = [hero1New, hero2, hero3, hero4, hero5, hero6];

const STEPS = [
  "Hightech Humanity (H2)®",
  "Free Digital Skills Mentoring",
  "Free Digital Skills Training",
  "Free Industry Certification",
  "Free Interview Preperation",
  "Free Resume/CV Preperation",
];

const HeroSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <HeroContainer id="home" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="HERO">
          {activeIndex === 0 && (
            <SectionContent
              key="First-Hero"
              title="3 Million Mind Movement (3M3) ™"
              cta="Crowdfunding"
              list={STEPS}
              to="crowdfunding"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="Second-Hero"
              title="3 Million Mind Movement (3M3) ™"
              description="We are crowdfunding contributions to help democratize technology. We belive that inspiration and empowerment are key elements to help maintain social equity. Our free services have already helped many people with the hand up they needed to establish thier digital career."
              cta="Crowdfunding"
              to="crowdfunding"
              {...StepperTransition}
            />
          )}

          {activeIndex === 2 && (
            <SectionContent
              key="Third-Hero"
              title="Onboarding"
              description="You will be welcomed and guided in a live one on one onboarding session with one of our Hightech Humanity (H2) ® community members."
              cta="Crowdfunding"
              to="crowdfunding"
              {...StepperTransition}
            />
          )}

          {activeIndex === 3 && (
            <SectionContent
              key="Fourth-Hero"
              title="Free Digital Skills Mentoring"
              description="You will be assigned at least one live mentor dedicated to your development and success. Our mentors typically have more than 15 years of experience in at least one digital area."
              cta="Crowdfunding"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}

          {activeIndex === 4 && (
            <SectionContent
              key="Fifth-Hero"
              title="Free Digital Skills Training"
              description="You will gain immedate access to the Microsoft Certified Trainers (MCT), Certified EC-Council Instructors (CEI), college professors, and other technical instructors who are already members of the Hightech Humanity (H2)® community. In addtion we are launching our Learning Managment System (LMS) where you have the possiblity to attend Microsoft, EC-Council, and custom in house training courses from your mobile phone on your schedule. "
              cta="Crowdfunding"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}
          {activeIndex === 5 && (
            <SectionContent
              key="Sixth-Hero"
              title="Certified Digital Professionial"
              description="Our mission is to prepare you for your new job or to establish your self an a independent cybersecurity contractor. In addtion you will join our network of industry porfessionals who are constantly recruiting new employees or contractors."
              cta="Crowdfunding"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>

      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="Hero"
      />
    </HeroContainer>
  );
};

export default HeroSection;
